<template>
	<v-list class="py-0 bg-transparent">
		<v-list-item-group>
			<v-list-item v-for="(element, key) in items" :key="key" class="d-flex align-center iconafterdash py-2 pl-0 pl-sm-auto px-0 pr-sm-auto"  :ripple="false">
				<v-list-item-icon class="align-self-center my-0 order-1 order-sm-0">
					<img src="@/assets/svg/blue-point.svg" class="" alt="">
				</v-list-item-icon>
				<v-list-item-content class="order-0 order-sm-1">
					<v-list-item-title class="white-space-initial">
						<div class="d-flex align-center">
							<div v-if="element.icon" class="">
								<img :src="element.icon" alt="" class="ml-0 mr-5 mx-sm-3 listiconimg">
							</div>
							<div class="black--text text-subtitle-1 white-space-initial" v-html="element.text"></div>
						</div>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>
export default {
    name: 'HowWork',
    props: ['items']
}
</script>

<style lang="scss">
.howwork{
    .listiconimg{
        height: 55px;
    }
    .v-list-item__icon{
        background-color:#fff;
    }
    .iconafterdash:not(:last-child)::before{
        background-color: transparent;
        border-left: 1px dashed #4e91fd;
        content: " ";
        height: 90%;
        position: absolute;
        opacity: 1;
        right: auto;
        left: 11px;
        top: 60%;
    }
    .iconafterdash:hover::before{
        opacity: 1 !important;
    }
    .v-list-item--link::before{
        background-color: transparent;
    }
    @media all and (max-width: 600px){
        .listiconimg{
            height: 30px;
        }
        .iconafterdash:not(:last-child)::before{
            left: 11px;
            top: 60%;
        }
        .v-list-item__icon:first-child{
            margin-right: 0;
        }
    }
}
</style>
