<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Patio and Driveway"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Patio and Driveway Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						– Every time dust,  dirt,  oil residue,  bird droppings,  and other substances find their way into the cracks of your driveway,  the expansion joints will start to expand,  little by little.<br/>

						A bit of dirt and a bit of seed is all your driveway and patio needs to become your next weed garden!<br/>
						This will look awful plus weeds setting up roots in the soil underneath your concrete or asphalt,  the more they will damage your driveway until pores become cracks and cracks become gaps.
						When moss,  algae,  mould,  and other natural debris collects on your driveway or patio,  it will rapidly fill in the cracks to make the surface smoother. Your driveway was built porous,  with friction to help it be good for tyre traction.
						The more natural debris that builds up,  the slicker your driveway gets,  which is bad for your tyres,  plus it can become a fall hazard too. Pressure washing the natural debris regularly will keep your driveway rough,  the way it was initially designed.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Patios and Driveways <br/>Cleaning Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Remove debris and leaves',
					text: 'One of the main benefits of roof cleaning is that it removes debris and leaves that can clog your gutters and down spouts.',
				},
				{
					title: 'Prevent moss and algae growth',
					text: 'Moss and algae can grow on your roof,  and while they may not seem like a big deal,  they can actually cause serious damage.',
				},
				{
					title: 'Improve curb appeal',
					text: 'A clean roof can do wonders for your home’s curb appeal. If you just want it to look its best,  roof cleaning can make a big difference.',
				},
				{
					title: 'Extend the life of your roof',
					text: 'One of the best benefits of roof cleaning is that it can actually extend the life of your roof.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Patio and Driveway Cleaning',
				text: 'Please,  read the FAQs for the patio and driveway cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'How long will it take to complete?',
						answer: 'Every job is different and we aim to get the job completed on the day (depending on the area size). We will have to return to your property to re-sand and add a sealant if required. We aim to complete every job within one week (weather dependent).',
					},
					{
						quest: 'How long will it last?',
						answer: 'Every job is different. We recommend cleaning your driveways and patios every one to two years to keep them in great condition.',
					},
					{
						quest: 'Will you beat a like for like quote?',
						answer: 'We do not beat quotes from other driveway cleaning companies. There are many other companies that may be  cheaper,  however the quality of their work falls below our standards. Our policy is a fair price for work carried out to the highest of service and standards.',
					},
					{
						quest: 'Why should we hire you instead of doing it ourselves?',
						answer: 'Because we are trained to it in a  professional way, with special equipment. Unless you  are trained and have professional equipment, you will never get manage professional driveway cleaning with a domestic pressure washer. It is possible to try it yourself, but it is time consuming  and all you will get is backache and a lot of mess. <br/>' +
							'Unless you  are trained and have professional equipment, you will never get manage professional driveway cleaning with a domestic pressure washer. It is possible to try it yourself, but it is time consuming  and all you will get is backache and a lot of mess.',
					},
					{
						quest: 'What machinery do you use?',
						answer: 'We use industrial petrol powered equipment. Our pressure washers are powerful enough for  good professional cleaning.',
					},
					{
						quest: 'Do you offer a weedkiller treatments?',
						answer: 'Yes, we do if needed.',
					},
					{
						quest: 'Do you use any chemicals to clean?',
						answer: 'Yes, we do. We use special chemicals. This will clean lichen,black spots, moss, and weeds at the same time.',
					},
					{
						quest: 'Can you remove stains?',
						answer: 'As part of our driveway cleaning,  block paving cleaning and patio cleaning services,  using bespoke chemicals and high pressure cleaning techniques,  we are able to remove most oil stains from concrete,  tarmac,  stone,  block paving and paving slabs. Oil stain removal can be done at any time after a spill. High pressure washing alone will not remove the oil stain. It is a combination of a number of our techniques to remove as much as physically possible. Whilst,  generally,  we are able to remove the oil stain completely,  we make you aware that 100% removal can not be guaranteed.',
					},
					{
						quest: 'Do you need to use our water?',
						answer: 'Yes we require water,  ideally an outdoor water tap. If you do not have an outdoor water tap,  we may have to use an indoor tap.',
					},
				]
			},
			process: {
				items: [
					{
						text: 'We scrape the worst <br/> of the debris off the roof',
					},
					{
						text: 'Apply a layer of biochemicals to break down and loosen remaining mildew,  moss and algae',
					},
					{
						text: 'Gently wash the solution <br/>(and all the grime) off the roof',
					},
					{
						text: 'We can then apply a sealant to protect against weather and keep the roof in good shape for longer',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
