<template>
    <div class="grey lighten-4 relative py-16">
        <v-container id="contact">
            <div>
                <title-component class="text-h2" title="Contact Us"/>
            </div>
            <v-row class="mt-3 mt-md-6">
                <v-col cols="12" md="7" lg="7" xl="8">
                    <v-card flat class="contact-card rounded-lg">
                        <v-card-text class="pa-6 pa-md-10">
                            <div class="text-h3 gray-text darken4 ls1px" style="font-weight: 700;">Request to Contact</div>
                            <contact-component/>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" lg="5" xl="3" offset-xl="1">
                    <div class="text-h4 gray-text darken4 mt-6">
                      V M Exterior Cleaning
                    </div>
                    <div class="mt-10">
                        <div class=""><a href="tel:077 6546 5656" class="text-decoration-none gray-text darken4 gilroy">077 6546 5656</a></div>
                        <div class="text-body-2 gray-text">Phone</div>
                    </div>
                    <div class="mt-6">
                        <div class=""><a href="mailto:info@vmec.co.uk" class="text-decoration-none gray-text darken4 gilroy">info@vmec.co.uk</a></div>
                        <div class="text-body-2 gray-text">E-mail</div>
                    </div>
                  <div class="mt-10">
                    <div class=""><a href="https://wa.me/447765465656" class="text-decoration-none gray-text darken4 gilroy">+44 7765 465656</a></div>
                    <div class="text-body-2 gray-text">Whatsapp</div>
                  </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/base/ContactComponent";
export default {
    name: "ContactSection",
    components: {
        ContactComponent,
		TitleComponent
    },
}
</script>

<style>
.contact-card{
    background: linear-gradient(135deg, #FFFFFF 0%, #FAFBFC 51.82%, #F5F7FA 100%);
    box-shadow: 0px 1px 1px rgba(9, 13, 20, 0.06), -4px -4px 8px rgba(255, 255, 255, 0.4), 8px 8px 24px rgba(9, 13, 20, 0.06)!important;
}
.theme--light.v-label{
    color: rgba(20, 16, 41, 0.4);
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
}
</style>
