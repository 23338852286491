<template>
	<layout/>
</template>
<script>
import layout from "@/components/layouts/v1/layout";
export default {
	components: {
		layout
	}
}
</script>
