<template>
    <div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-row class="mt-4">
                <v-col cols="12" :md="compact == true ? 6 : 12" lg="6">
                    <label class="gray-text text-overline">Phone Number</label>
                    <v-text-field
                        v-model="form.phone"
                        :rules="phoneRules"
                        hide-details="auto"
                        solo
                        flat
                        background-color="rgba(20, 16, 41, 0.03)"
                        label="Your Phone Number"
                        class="mt-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" :md="compact == true ? 6 : 12" lg="6">
                    <label class="gray-text text-overline">Email</label>
                    <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        hide-details="auto"
                        solo
                        flat
                        background-color="rgba(20, 16, 41, 0.03)"
                        label="Your Email"
                        class="mt-2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                    <label class="gray-text text-overline">How can we help</label>
                    <v-textarea
                        v-model="form.text"
                        solo
                        flat
                        hide-details
                        background-color="rgba(20, 16, 41, 0.03)"
                        label="Add description"
                        class="mt-2"
                        rows="3"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-form>
        <div class="mt-10 d-lg-flex" :class="{'d-md-flex': compact == true}">
        <v-btn class="primary" @click="apply()">Get help</v-btn>
        </div>
    </div>
</template>

<script>

export default {
    name: "ContactComponent",
    props: ['compact'],
    components: {
    },
    data: () => ({
        valid: true,
        form: {
            phone: '',
            email: '',
            text: '',
        },
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{2,10}$/im.test(v) || 'Phone must be valid',
        ],
    }),
    methods: {
        apply(){
            if(this.$refs.form.validate()){
                this.axios.post('https://api.vmec.co.uk/sendmail.php', this.form).then(() => {
                    this.$refs.form.reset();
                    this.$store.state.dialog = false;
                    this.$store.state.confirm = true;
                }).catch(() => {
                    console.log('Network Error');
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
