<template>
	<v-container fluid class="d-flex blue h-1vh pa-0" style="overflow: hidden">
		<div class="relative h-100 d-none d-lg-block">
			<div class="absolute z100 blue ">
				<img src="@/assets/log.png" class="logo-150 ">
			</div>
			<div class="d-flex align-end h-100 pb-6">
				<div class="d-flex flex-column justify-center align-center" style="width: 140px">
					<div class="line"></div>
					<div class="rounded-circle border-icons d-flex justify-center align-center my-4">
						<v-icon class=""  color="white">fa fa-brands fa-facebook-f</v-icon>
					</div>
					<div class="rounded-circle border-icons d-flex justify-center align-center my-4">
						<v-icon class=""  color="white">fa fa-brands fa-instagram</v-icon>
					</div>
					<div class="rounded-circle border-icons d-flex justify-center align-center my-4">
						<v-icon class=""  color="white">fa fa-brands fa-youtube</v-icon>
					</div>
				</div>
			</div>
		</div>
		<div class="w-100 height-800 relative">
			<v-img
					src="@/assets/images/home-welcome.92b17955.png"
					gradient="rgba(4, 5, 27, 0.6), rgba(4, 5, 27, 0.6)"
					class="absolute w-100 h-100"
					cover
			/>
			<v-container fluid class="pt-md-16 pb-0 px-4 px-md-12 h-100 d-flex flex-column justify-space-between relative index1">
				<v-row class="pt-16 mt-7 h-100 pb-16 mb-6">
					<v-col cols="12" lg="5" class="d-flex flex-column justify-space-between">
						<h4 class="text-h6 text-sm-h5 text-md-h4 text-lg-h4 white--text font-weight-light white--text ml-lg-15  pl-lg-12 text-center text-lg-left">V M Exterior Cleaning <br/> The best choice</h4>
					</v-col>
					<v-col cols="12" lg="4" class="d-flex flex-column justify-space-between">
						<div class="d-none d-lg-block"></div>
						<div class="text-center">
							<router-link :to="{name: 'roofs'}" class="d-block text-h6 text-sm-h5 text-md-h4 text-lg-h3 my-3 azonix mainservices text-decoration-none">Roofs</router-link>
							<router-link :to="{name: 'gutter'}" class="d-block text-h6 text-sm-h5 text-md-h4 text-lg-h3 my-3 azonix mainservices text-decoration-none">Gutters</router-link>
							<router-link :to="{name: 'window'}" class="d-block text-h6 text-sm-h5 text-md-h4 text-lg-h3 my-3 azonix mainservices text-decoration-none">Windows</router-link>
							<router-link :to="{name: 'patiocriveway'}" class="d-block text-h6 text-sm-h5 text-md-h4 text-lg-h3 my-3 azonix mainservices text-decoration-none">Driveways</router-link>
						</div>
						<div class="mb-2 py-10 py-md-0 text-center text-md-left mx-auto">
							<v-btn class="font-link-16-60 py-4 px-8 white--text blue" @click="$refs.callrequest.dialog = true">
								<span>Get Request</span>
								<v-icon>mdi-arrow-right</v-icon>
								<!-- <img :src="require('@/assets/icon/arrow_right_white.svg')" alt=""> -->
							</v-btn>
						</div>
					</v-col>
<!--					<v-col lg="3" class="relative d-none d-md-flex flex-column justify-space-between align-end">-->
<!--						<div class="socialization" style="width:20px;">-->
<!--							<div class="d-flex align-center justify-center ">-->
<!--								<a class="white&#45;&#45;text text-decoration-none font-12-normal" target="_blank" >facebook</a>-->
<!--								<div class="dot white rounds-50 my-5"></div>-->
<!--								<a class="white&#45;&#45;text text-decoration-none font-12-normal" target="_blank" >instagram</a>-->
<!--							</div>-->
<!--						</div>-->
<!--					</v-col>-->
				</v-row>
			</v-container>
		</div>
		
<!--		-->
<!--		<div class="flex-grow-1 relative">-->
<!--			<v-img-->
<!--					src="@/assets/images/home-welcome.92b17955.png"-->
<!--					gradient="rgba(4, 5, 27, 0.6), rgba(4, 5, 27, 0.6)"-->
<!--					class="absolute w-100 h-100"-->
<!--			/>-->
<!--			<v-container class="d-md-none">-->
<!--				<div class="text-h3 white&#45;&#45;text font-weight-light">-->
<!--					Blue Whale <br/>Cleaning Company <br/> The best choice-->
<!--				</div>-->
<!--			</v-container>-->
<!--			<v-container fluid class="d-none d-md-block absolute t-0 b-0 l-0 r-0">-->
<!--				<v-row class="h-100">-->
<!--					<v-col cols="12" lg="2" offset-lg="1">-->
<!--						<div class="py-16"></div>-->
<!--						<div class="text-h3 white&#45;&#45;text font-weight-light">-->
<!--							Blue Whale <br/>Cleaning Company <br/> The best choice-->
<!--						</div>-->
<!--					</v-col>-->
<!--					<v-col cols="12" lg="6" class="white&#45;&#45;text d-flex flex-column justify-center align-center pl-10">-->
<!--						<div class="py-10"></div>-->
<!--						<router-link :to="{name: 'roofs'}" class="text-h3 my-3 azonix mainservices text-decoration-none">Roofs</router-link>-->
<!--						<router-link :to="{name: 'gutter'}" class="text-h3 my-3 azonix mainservices text-decoration-none">Gutters</router-link>-->
<!--						<router-link :to="{name: 'window'}" class="text-h3 my-3 azonix mainservices text-decoration-none">Windows</router-link>-->
<!--						<router-link :to="{name: 'patiocriveway'}" class="text-h3 my-3 azonix mainservices text-decoration-none">Driveways</router-link>-->
<!--					</v-col>-->
<!--					<v-col cols="12" lg="2" class="d-flex justify-end align-end">-->
<!--						<div class="text-h5 white&#45;&#45;text font-weight-light text-right">-->
<!--							<div class="py-3"><a href="tel:(509) 579 3104" class="text-decoration-none font-weight-bold white&#45;&#45;text gilroy"> +44 (509) 579 3104</a></div>-->
<!--							<div class="py-3"><a href="mailto:info@vmec.co.uk" class="text-decoration-none font-weight-bold white&#45;&#45;text gilroy"> info@vmec.co.uk</a></div>-->
<!--						</div>-->
<!--						-->
<!--						<div class="py-16"></div>-->
<!--					</v-col>-->
<!--				</v-row>-->
<!--			</v-container>-->
<!--		</div>-->
	</v-container>
</template>

<script>
export default {
	name: "MainComponent"
}
</script>

<style scoped>
.h-1vh{
	height: 100vh;
}
.border-icons{
	width: 50px;
	height: 50px;
	border: 1px solid rgba(255,255,255,0.4);
}
.line{
	width: 1px;
	background: rgba(255,255,255,0.4);
	height: 100px;
}
.mainservices:hover{
	color: transparent !important;
	-webkit-text-stroke: 2px #fff;
	cursor: pointer;
	font-size: 3.5rem !important;
}
.mainservices{
	color: white !important;
	-webkit-text-stroke: 0;
	transition: 0.3s;
}
</style>
