import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from "@/views/MainPage";
import BlogPage from "@/views/BlogPage";
import ContactPage from "@/views/ContactPage";
import WorkPage from "@/views/WorkPage";
import RoofPage from "@/views/Services/RoofPage";
import GutterPage from "@/views/Services/GutterPage.vue";
import SoffitFasciaPage from "@/views/Services/SoffitFasciaPage.vue";
import WindowPage from "@/views/Services/WindowPage.vue";
import ConservatoryPage from "@/views/Services/ConservatoryPage.vue";
import PatioDrivewayPage from "@/views/Services/PatioDrivewayPage.vue";
import WallFencePage from "@/views/Services/WallFencePage.vue";
import RenderWallPage from "@/views/Services/RenderWallPage.vue";
import SolarPanelPage from "@/views/Services/SolarPanelPage.vue";
import DeckingPage from "@/views/Services/DeckingPage.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: MainPage
    },
    {
        path: '/services/roofs',
        name: 'roofs',
        component: RoofPage
    },
    {
        path: '/services/gutter',
        name: 'gutter',
        component: GutterPage
    },
    {
        path: '/services/soffit-and-fascia',
        name: 'soffitfascia',
        component: SoffitFasciaPage
    },
    {
        path: '/services/window',
        name: 'window',
        component: WindowPage
    },
    {
        path: '/services/conservatory',
        name: 'conservatory',
        component: ConservatoryPage
    },
    {
        path: '/services/patio-and-driveway',
        name: 'patiocriveway',
        component: PatioDrivewayPage
    },
    {
        path: '/services/wall-and-fence',
        name: 'wallfence',
        component: WallFencePage
    },
    {
        path: '/services/render-wall',
        name: 'renderwall',
        component: RenderWallPage
    },
    {
        path: '/services/solar-panel',
        name: 'solarpanel',
        component: SolarPanelPage
    },
    {
        path: '/services/decking',
        name: 'decking',
        component: DeckingPage
    },
    {
        path: '/work',
        name: 'work',
        component: WorkPage
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogPage
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactPage
    }


    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
