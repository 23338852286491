module.exports = {
    titles: [
        {
            key: 'all',
            title: 'All Questions'
        },
        {
            key: 'roof',
            title: 'Roofs'
        },
        {
            key: 'gutter',
            title: 'Gutters'
        },
        {
            key: 'conservatory',
            title: 'Conservatory'
        },
        {
            key: 'driveways',
            title: 'Driveways'
        }
    ],
    qas: [
        {
            quest: 'Why is roof cleaning important?',
            answer: 'It’s easy to forget about the roof of your home,  but it is a very important part of the building. Generally,  the roof doesn’t need too much attention,  but every three to five years,  roofs can benefit from a clean. Roof cleaning removes lichen,  moss,  and algae from the surface. A build-up can stain your roof and begin to damage the surface material. ' +
                '<br/>A periodical roof cleaning will slow the growth of mildew,  and make any cracks or splits easier to spot from the ground. You will also see a reduction in the amount of moss falling into the gutters.',
            section: 'roof',
        },
        {
            quest: 'Can I complete roof cleaning myself?',
            answer: 'Cleaning a roof yourself isn’t an easy job. To make sure you are safe,  you’ll need scaffolding and a washing system that won’t damage roofing material. Without the right equipment,  you could damage your roof,  make a mess on your walls and put yourself in danger. Save yourself the stress and hard work and book a roof cleaning specialist.' +
                '<br/>We prepare the ground with sheeting to protect the area and use the best equipment available that is specially designed to clean roofs perfectly. The guttering is cleared as part of the process and we tidy up after ourselves.',
            section: 'roof',
        },
        {
            quest: 'Will the soft wash chem harm my roof?',
            answer: 'We use a safe non aggressive product that has an excellent environmental footprint. The product does not damage the material being treated and there is no physical alteration of the surface.',
            section: 'roof',
        },
        {
            quest: 'Will the roof be cleaned with instant results?',
            answer: 'We remove 95% of moss by hand and  soft wash the roof with professional chemical. The cleansing process starts immediately and the original colour of the roof tiles starts to come back within 2 hours.The moss and lichen are killed instantly and typically take 12 months to erode away from the roof structure, this is dependent on weather conditions (rain/wind etc).',
            section: 'roof',
        },
        {
            quest: 'Is your product safe to homeowners and animals?',
            answer: 'It is safe for you and your pets.',
            section: 'roof',
        },
        {
            quest: 'Will you walk on my roof?',
            answer: 'We do not walk on roofs and to gain access we use roof ladders and scaffolding.',
            section: 'roof',
        },
        {
            quest: 'Do you remove any moss?',
            answer: 'We remove by hand 95% of moss.',
            section: 'roof',
        },
        {
            quest: 'Can you undertake any roof repairs?',
            answer: 'We are not a roofing company and we recommend professional roof installers to carry out any repairs if any. ',
            section: 'roof',
        },
        {
            quest: 'Can moss on my roof be damaging?',
            answer: 'Moss generally grows in the vertical joint between tiles and often at the tail end. The end result is increased water ingress where tiles overlap which can damage the fixings and timber battens.',
            section: 'roof',
        },
        {
            quest: 'Are you insured?',
            answer: 'We are fully insured for your complete peace of mind.',
            section: 'roof',
        },
        {
            quest: 'Will you leave our property clean and tidy?',
            answer: 'Cleaning patios and driveways is a messy job however we will leave your property clean and tidy.',
            section: 'roof',
        },
        {
            quest: 'How long will the roof cleaning last?',
            answer: 'We cannot give you an exact timescale however typically we find that 5 to 8 years is possible. This all depends on mother nature and how aggressive the winters are during this period. Also cross contamination from trees or neighbouring properties can affect this timescale.',
            section: 'roof',
        },
        {
            quest: 'Why spotless gutters are so important?',
            answer: 'Blocked gutters can cause a lot of damage to your property.<br/>When rainwater can’t flow properly along with the guttering and through the downpipe, it starts spilling over the edge of the gutters and down the walls.<br/>You can end up with damp walls and algae on the exterior surface.<br/>A continuous dripping noise often sets in whenever it rains, this can wake you at night and be very frustrating for you and your neighbours.<br/>' +
                'Clogged gutters look dirty and can affect the look of your home.<br/>Fortunately, all of this can be avoided with regular cleaning and clearing of your gutters. Once we are finished, not only will the rainwater flow efficiently through the gutters, but the outside of your home will look fantastic too.',
            section: 'gutter'
        },
        {
            quest: 'How do you clean out our gutters?',
            answer: 'We use our powerful gutter vac, with strong poles and special developed heads and nozzles, to pick all deposits from your gutters.',
            section: 'gutter'
        },
        {
            quest: 'Can you remove large weeds from my gutters?',
            answer: 'We use a special head to cut the soil and weeds, then safely remove them. We don\'t recommend waiting until the gutters are full and the weeds grow. It is not safe and may need expensive repairs.',
            section: 'gutter'
        },
        {
            quest: 'Can I be sure my gutters have been properly cleared by you?',
            answer: 'You can ask for "before" and "after" photos of your gutters.',
            section: 'gutter'
        },
        {
            quest: 'How much does this gutter cleaning service cost?',
            answer: 'When the customer asks for a quote, he or she gets the price.',
            section: 'gutter'
        },
        {
            quest: 'How do I get a quote please?',
            answer: 'If you need a quote, contact us.',
            section: 'gutter'
        },
        {
            quest: 'How long do I have to wait for a gutter clearing?',
            answer: 'After the customer  contacts us for a quote. We will discuss the soonest and most convenient date.',
            section: 'gutter'
        },
        {
            quest: 'Do I need to be there for you to clear my gutter?',
            answer: 'To carry out a gutter clean we will need access to the front and back areas. You don’t necessarily need to be there for us to carry out the clean as we carry our own generator for the electricity. On completion of the clean we will leave you an invoice where you can pay by Internet banking cheque-book or cash.',
            section: 'gutter'
        },
        {
            quest: 'I have a shared down pipe with my neighbour?',
            answer: 'We will just clear just your gutter run if you want, but you can speak to your neighbour to take advantage of our neighbour discount if both jobs are booked at the same time and both gutter clears are completed on the same day.',
            section: 'gutter'
        },
        {
            quest: 'Do you wash gutters and fascias ?',
            answer: 'We can do this for you and it will be double the price of the gutter clean quote. Clean the out side of the Gutters/Fascias/Soffits and Down Pipe. We also apply a specially formulated soft wash chemical that will clean and also kill all the algae, lichen growths',
            section: 'gutter'
        },
        {
            quest: 'Do you have insurance?',
            answer: 'We are fully insured for your complete peace of mind.',
            section: 'gutter'
        },
        {
            quest: 'Will you leave our property clean and tidy?',
            answer: 'We will leave your property clean and tidy.',
            section: 'gutter'
        },
        {
            quest: 'I have the point missing off the top of my conservatory. Will this be a problem?',
            answer: 'We assess your conservatory before we start to clean it and structural or other problems we may encounter.',
            section: 'conservatory'
        },
        {
            quest: 'How long will it take to clean my conservatory?',
            answer: 'This depending on the size but usually 3-4 hours for an average sized conservatory (approximately 3 x 4 metres)',
            section: 'conservatory'
        },
        {
            quest: 'Do you stand on the conservatory?',
            answer: 'No,  we have specialist access facilities and equipment that ensures we do not stand on your conservatory.',
            section: 'conservatory'
        },
        {
            quest: 'How do you clean my conservatory?',
            answer: 'We use a special developed solution that is not harmful.',
            section: 'conservatory'
        },
        {
            quest: 'What chemicals do you use?',
            answer: 'We have had specially developed environmentally friendly solution that is not harmful to plants and animals.',
            section: 'conservatory'
        },
        {
            quest: 'My conservatory is dirty and green. Can you clean it?',
            answer: 'Yes, we can in most cases.',
            section: 'conservatory'
        },
        {
            quest: 'Why should my conservatory be cleaned when it goes green?',
            answer: 'The green coloured growth is usually caused by algae. This build up not only looks ugly but also obscures light from entering the conservatory. Algae begins to grow on the polycarbonate or glass and in some cases under the seals of the roof. This can force the seals apart over time and cause leaks into the conservatory.',
            section: 'conservatory'
        },
        {
            quest: 'I have tried to clean the conservatory myself but it is still green. Can you help?',
            answer: 'In most cases,  yes. However,  we have come across conservatories where the algae has been allowed to grow over the roof for several years. This has allowed the sun to bake a coating of the algae onto the roof making it impossible to remove and causing the roof to be permanently green.',
            section: 'conservatory'
        },
        {
            quest: 'Do you clean gutters?',
            answer: 'Yes,  we clean you conservatory gutters as part of our service. We can also clean house gutters and fascias around you house. This is at an extra cost.',
            section: 'conservatory'
        },
        {
            quest: 'How long will it take to complete?',
            answer: 'Every job is different and we aim to get the job completed on the day (depending on the area size). We will have to return to your property to re-sand and add a sealant if required. We aim to complete every job within one week (weather dependent).',
            section: 'driveways'
        },
        {
            quest: 'How long will it last?',
            answer: 'Every job is different. We recommend cleaning your driveways and patios every one to two years to keep them in great condition.',
            section: 'driveways'
        },
        {
            quest: 'Will you beat a like for like quote?',
            answer: 'We do not beat quotes from other driveway cleaning companies. There are many other companies that may be  cheaper,  however the quality of their work falls below our standards. Our policy is a fair price for work carried out to the highest of service and standards.',
            section: 'driveways'
        },
        {
            quest: 'Why should we hire you instead of doing it ourselves?',
            answer: 'Because we are trained to it in a  professional way, with special equipment. Unless you  are trained and have professional equipment, you will never get manage professional driveway cleaning with a domestic pressure washer. It is possible to try it yourself, but it is time consuming  and all you will get is backache and a lot of mess. <br/>' +
                'Unless you  are trained and have professional equipment, you will never get manage professional driveway cleaning with a domestic pressure washer. It is possible to try it yourself, but it is time consuming  and all you will get is backache and a lot of mess.',
            section: 'driveways'
        },
        {
            quest: 'What machinery do you use?',
            answer: 'We use industrial petrol powered equipment. Our pressure washers are powerful enough for  good professional cleaning.',
            section: 'driveways'
        },
        {
            quest: 'Do you offer a weedkiller treatments?',
            answer: 'Yes, we do if needed.',
            section: 'driveways'
        },
        {
            quest: 'Do you use any chemicals to clean?',
            answer: 'Yes, we do. We use special chemicals. This will clean lichen,black spots, moss, and weeds at the same time.',
            section: 'driveways'
        },
        {
            quest: 'Can you remove stains?',
            answer: 'As part of our driveway cleaning,  block paving cleaning and patio cleaning services,  using bespoke chemicals and high pressure cleaning techniques,  we are able to remove most oil stains from concrete,  tarmac,  stone,  block paving and paving slabs. Oil stain removal can be done at any time after a spill. High pressure washing alone will not remove the oil stain. It is a combination of a number of our techniques to remove as much as physically possible. Whilst,  generally,  we are able to remove the oil stain completely,  we make you aware that 100% removal can not be guaranteed.',
            section: 'driveways'
        },
        {
            quest: 'Do you need to use our water?',
            answer: 'Yes we require water,  ideally an outdoor water tap. If you do not have an outdoor water tap,  we may have to use an indoor tap.',
            section: 'driveways'
        },

    ]
}
