<template>
	<div v-if="t"><span :class="textcolor ? textcolor : 'secondary--text'" v-html="t[0]"></span> <span class="primary--text" v-html="t[1]"></span></div>
</template>

<script>
export default {
	name: "TitleComponent",
	props: ['title', 'textcolor'],
	data: () => ({
		t: false
	}),
	mounted() {
		
		this.t = {
			0: this.title.split(' ')[0],
			1: this.title.split(' ').slice(1).join(' '),
		}
	}
}
</script>

<style type="scss">
.bordered-text-secondary{
	color: transparent !important;
	-webkit-text-stroke: 1px #4e91fc;
}
</style>
