<template>
	<div>
		<contact-component/>
	</div>
</template>

<script>
import ContactComponent from "@/components/pages/sections/ContactComponent";
export default {
	name: "ContactPage",
	components: {
		ContactComponent
	}
}
</script>

<style scoped>

</style>
