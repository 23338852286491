import { render, staticRenderFns } from "./RenderWallPage.vue?vue&type=template&id=13c8141c&scoped=true&"
import script from "./RenderWallPage.vue?vue&type=script&lang=js&"
export * from "./RenderWallPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c8141c",
  null
  
)

export default component.exports