<template>
	<div>
		<main-component/>
		<about-component/>
		<services-component/>
		<faq-component/>
<!--		<blog-component/>-->
		<contact-component/>
	</div>
</template>

<script>
import AboutComponent from "@/components/pages/sections/AboutComponent";
import ServicesComponent from "@/components/pages/sections/ServicesComponent";
import FaqComponent from "@/components/pages/sections/FaqComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
// import BlogComponent from "@/components/pages/sections/BlogComponent";
import MainComponent from "@/components/pages/sections/MainComponent";
export default {
	name: "MainPage",
	components: {
		MainComponent,
		AboutComponent,
		ServicesComponent,
		FaqComponent,
		ContactComponent,
		// BlogComponent
	}
}
</script>

<style scoped>

</style>
