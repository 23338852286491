<template>
	<v-container>
		<title-component class="text-h1 text-center mb-16" title="Our Work"/>
		<div class="d-flex justify-center mb-15">
			<div class="text-h6 azonix">Type 1</div>
			<div class="divider">
				<v-icon class="mx-4" color="accent" x-small>fas fa-circle-dot</v-icon>
			</div>
			<div class="text-h6 azonix">Type 2</div>
			<div class="divider">
				<v-icon class="mx-4" color="accent" x-small>fas fa-circle-dot</v-icon>
			</div>
			<div class="text-h6 azonix">Type 3</div>
			<div class="divider">
				<v-icon class="mx-4" color="accent" x-small>fas fa-circle-dot</v-icon>
			</div>
			<div class="text-h6 azonix">Type 4</div>
			<div class="divider">
				<v-icon class="mx-4" color="accent" x-small>fas fa-circle-dot</v-icon>
			</div>
			<div class="text-h6 azonix">Type 5</div>
		</div>
		<div class="after-line">
			<v-row class="h-100 py-10 my-16 ">
				<v-col cols="12" md="6" class="">
					<v-img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg"/>
				</v-col>
				<v-col cols="12" md="6" class="d-flex justify-center align-center text-center">
					<div class="px-8">
						<div class="text-h4 py-8">
							Service Type
						</div>
						<div class="mb-6 grey--text text--darken-1">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea eius est nam odit officiis
							pariatur, rerum sapiente? Alias animi architecto asperiores dolorem fuga, in itaque
							molestias natus, praesentium quisquam, tempore?
						</div>
						<div>
							<v-btn class="transparent" depressed @click="dialog = true">Read More <v-icon class="ml-2">fa-solid fa-ellipsis</v-icon></v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<div class="after-line">
			<v-row class="h-100 py-10 my-16 ">
				<v-col cols="12" md="6" class="d-flex justify-center align-center text-center">
					<div class="px-8">
						<div class="text-h4 py-8">
							Service Type
						</div>
						<div class="mb-6 grey--text text--darken-1">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea eius est nam odit officiis
							pariatur, rerum sapiente? Alias animi architecto asperiores dolorem fuga, in itaque
							molestias natus, praesentium quisquam, tempore?
						</div>
						<div>
							<v-btn class="transparent" depressed @click="dialog = true">Read More <v-icon class="ml-2">fa-solid fa-ellipsis</v-icon></v-btn>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="6" class="">
					<v-img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg"/>
				</v-col>
			</v-row>
		</div>
		<div class="after-line">
			<v-row class="h-100 py-10 my-16 ">
				<v-col cols="12" md="6" class="">
					<v-img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg"/>
				</v-col>
				<v-col cols="12" md="6" class="d-flex justify-center align-center text-center">
					<div class="px-8">
						<div class="text-h4 py-8">
							Service Type
						</div>
						<div class="mb-6 grey--text text--darken-1">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea eius est nam odit officiis
							pariatur, rerum sapiente? Alias animi architecto asperiores dolorem fuga, in itaque
							molestias natus, praesentium quisquam, tempore?
						</div>
						<div>
							<v-btn class="transparent" depressed @click="dialog = true">Read More <v-icon class="ml-2">fa-solid fa-ellipsis</v-icon></v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<v-dialog v-model="dialog" max-width="1000">
			<v-card flat>
				<v-card-text class="pa-8">
					<div class="text-h3 grey--text text--darken-4 text-center py-8">Service Type</div>
					<div class="text-subtitle-1 px-8 text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea eius est nam odit officiis
						pariatur, rerum sapiente? Alias animi architecto asperiores dolorem fuga, in itaque
						molestias natus, praesentium quisquam, tempore?
					</div>
					<div class="text-center">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
						<img src="https://lirp.cdn-website.com/a39e8654/dms3rep/multi/opt/softwash-exterior-cleaning-2-640w.jpg" class="pa-4" height="150">
					</div>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn class="transparent" depressed @click="dialog = false"><v-icon class="mr-3">fa-solid fa-xmark</v-icon> Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
export default {
	name: "WorkPage",
	components: {
		TitleComponent
	},
	data:() => ({
		dialog: false
	})
}
</script>

<style type="scss">
.border-work-item{
	border-top: 1px solid;
	border-top-color: #4e91fc;
}
.divider{
	margin: 0 8px;
	
}
.after-line::after{
	content: " ";
	display: block;
	width: 200px;
	height: 3px;
	background-color: #4e91fc;
	margin: 0 auto;
}
</style>
