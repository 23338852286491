<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Windows"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Window Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						For window cleaning We use a sophisticated pure water system,  with a water fed brush. This service always includes washing down the frames and the sills,  making sure that the whole window is kept clean.<br/>We offer premium residential window cleaning services using only the best pure water cleaning systems,  to leave your windows crystal clear. Our skilled team use only the best technology to guarantee you’ll have streak-free windows after every clean. It also allows us to operate year round,  meaning your home will always look at its best.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Window Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Let in More Light',
					text: 'How clean a window is directly impacts how much light can filter into your home. If window glass is not clean,  less light will be able to come into the space and the home will appear dark and dingy.',
				},
				{
					title: 'Protect Energy Efficiency',
					text: 'Dirt particles that build up on your window may lower your home’s heating efficiency,  so the dirtier your windows are,  the less sun is able to penetrate and warm your home.',
				},
				{
					title: 'Boost Curb Appeal and First Impressions',
					text: 'The beauty you see from the outside of your home,  and the sights you enjoy from the inside,  are largely affected by whether or not your windows are clean.',
				},
				{
					title: 'Extend the life of your windows.',
					text: 'Just like stubborn lime deposits cling to showers,  unwanted deposits can accumulate on your glass from aluminum screens. And etched glass looks bad and can be more susceptible to cracking and chipping.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Window Cleaning',
				text: 'Please,  read the FAQs for the window cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'How often should my windows be cleaned?',
						answer: 'You can pay for a one off clean,  or as often as you like,  however due to the build up of grime,  pollution and dust we recommend a monthly clean.',
					},
					{
						quest: 'What is the difference between a first time clean and regular cleaning?',
						answer: 'As windows collect dirt over time the initial clean takes longer and is more costly,  however,  if you have regular cleans they will be quicker and cheaper.',
					},
					{
						quest: 'Can you still clean windows when it is raining?',
						answer: 'We come rain or shine. In general commercial properties can be cleaned regardless of the weather,  be it rain,  snow,  or windy as they are cleaned more regularly. Private homes however are usually done less regularly,  so we prefer a dry day to get the best results.',
					},
					{
						quest: 'Why do I need a professional window cleaner?',
						answer: 'Cleaning your own interior and exterior home or commercial windows can be time consuming and many off the shelf window cleaning products leave streaks. So to ensure your windows look their best we recommend hiring a professional window cleaner.',
					},
					{
						quest: 'How long does it take?',
						answer: 'Depends how many windows you have and what mood we’re in.',
					},
					{
						quest: 'Do you use ladders?',
						answer: 'Yes we use ladders but only when we can’t reach on tiptoes.',
					},
					{
						quest: 'Are you insured?',
						answer: 'We have public and employers liability insurance and are fully compliant with health and safety standards.',
					},
					{
						quest: 'Do you clean the window ledges?',
						answer: 'We clean the window ledges as standard removing any debris that have built up over time.',
					},
				]
			},
			process: {
				items: [
					{
						text: 'Get peace of mind that proper window cleaning supplies,  tools,  and techniques will be applied.',
					},
					{
						text: 'Improve the longevity of your windows by giving them the utmost care.',
					},
					{
						text: 'Delegate time-consuming window cleaning to the pros and free up your time so you can do what you love most.',
					},
					{
						text: 'Discover the cause for unsightly spots on your windows. Professional window cleaners can offer great solutions to prevent unwanted spots on your windows in the future. ',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
