<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Walls and Fences"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Wall and Fence Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						For a professional garden wall or fence cleaning,  look no further. We are ready to refresh and restore your fences and walls to their former glory. Using our soft wash system,  we clean walls and fences without damaging any of the timber or brickwork. A clean fence improves the look of your garden and gives your home more kerb appeal.<br/>
						Stains,  moss,  and mildew can be removed from garden walls and fences by our team. Give your garden a fresh new look with our washing service. Our non-harsh chemicals have been specially formulated to be gentle on timber or brick. We spray the solution onto the wall and fence surface,  getting it into all the corners,  nooks,  and crannies. We take our time to make sure every inch is cleaned to your satisfaction.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Roof Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Security and Safety',
					text: 'Fences are proven to act as a deterrent to intruders and trespassers,  improving the security of the structure they surround. As well as keeping uninvited guests out,  a fence also stops children and pets from getting out,  ensuring that they stay safe when playing in the garden.',
				},
				{
					title: 'Clear Boundaries',
					text: 'A fence acts as an unmissable boundary between properties,  preventing accidental or intentional trespassing. Boundaries that are not properly delineated can cause disagreements but a fence is easily understood by anyone.',
				},
				{
					title: 'Improved Privacy',
					text: 'Our gardens should be private places for us to relax in and enjoy. A fence can help to deliver that privacy and also make a good natural line for trees to be planted along,  further enhancing privacy levels.',
				},
				{
					title: 'Improved Aesthetics',
					text: 'Most fences are built for purely practical reasons but the fact is that a carefully-chosen visual style can dramatically increase the aesthetic value of a property. The fence will add a strong sense of structure to the outdoor space.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Roof Cleaning',
				text: 'Please,  read the FAQs for the roof cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'Where can I build my fence?',
						answer: 'Fences can be built up to your property line.',
					},
					{
						quest: 'How do I know where my property line is?',
						answer: 'If you do not know where your property lines are,  a survey is the most accurate way to know where these boundaries and any potential restrictions (e.g.,  easements) are. Older plats and site plans for your property can also be helpful if the property lines have been included and are accurate,  but should be updated to reflect any changes.',
					},
					
					{
						quest: 'How deep should a fence post be?',
						answer: 'The depth of your fence post depends on several factors,  including the height of the fence,  the width of the post and the type of post. However,  following a few general rules will help to prevent fencing disasters.\n' +
							'Most experts suggest you dig fence pole holes to a depth of at least 600mm. If your fence is particularly tall,  you may need to go slightly deeper to around 750mm. A popular way of working out the preferred depth of a post hole is to divide the above-ground height of the post by three. For instance,  if you have a fence that’s 210cm tall,  you will need a hole that’s 70cm (or 700mm) deep.',
					},
					{
						quest: 'How high can a garden fence be?',
						answer: 'Without planning permission,  your fence can be a maximum of two metres high unless it\'s beside a road,  in which case a one-metre height restriction applies. Anything above these dimensions will require planning permission,  and the rules governing those structures vary from region to region.',
					},
				]
			},
			process: {
				items: [
					{
						text: 'We mix our cleaning solutions after a close inspection of the timber or brick surface to be cleaned.',
					},
					{
						text: 'Once applied,  we let the solution do its magic for up to 60 minutes to ensure we get the best results.',
					},
					{
						text: 'The next step is to gently rinse all the solutions away,  leaving behind clean garden fences and walls.',
					},
					{
						text: 'After completing the cleaning process,  we can apply a sealant to timber or brick.',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
