<template>
    <v-card flat class="starcard h-100 bg-transparent">
        <v-card-text class="pa-0">
			<h1 class="text-h2 number"><slot name="number"/></h1>
            <h4 class="text-20-60 my-4"><slot name="title"/></h4>
            <p class="font-16"><slot name="description"/></p>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'StarCard',
}
</script>

<style lang="scss">
.starcard{
	background: transparent !important;
    padding: 30px;
    border: 1px solid #CDE0FF !important;
    box-sizing: border-box;
    border-radius: 7px;
    transition: 0.2s;
    .hover{
        display: none;
    }
    .unhover{
        display: block;
    }
	.number{
		color: #2196f3;
	}
}
.starcard:hover .number{
	color: white;
}
@media all and (min-width:960px){
    .starcard:hover{
        background-color: #4e91fd !important;
        color: #fff;
        h4,p{
            color: #fff;
        }
        .hover{
            display: block;
        }
        .unhover{
            display: none;
        }
    }
}
</style>
