<template>
	<v-container>
		<title-component class="text-h1 text-center mb-16" title="Blog"/>
		<v-row class="h-100 pb-12">
			<v-col cols="12" md="6" v-for="(itm, key) in news" :key="key">
				<blog-card :item="itm"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import BlogCard from "@/components/base/BlogCard";
import TitleComponent from "@/components/base/TitleComponent";
export default {
	name: "BlogPage",
	components: {
		BlogCard,
		TitleComponent
	},
	data: () => ({
		news: [],
	}),
	mounted() {
		this.getNews();
	},
	methods: {
		getNews(){
			this.axios.get('/api/v1/frontend/blog').then(response => {
				this.news = response.data.map(item => {
					item.content.image = item.image
					item.content.date = item.created_at
					item.content.id = item.id
					return item.content
				})
			})
		}
	}
}
</script>

<style scoped>

</style>
