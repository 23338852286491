import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 0,
            sm: 768,
            md: 1040,
            lg: 1360,
            xl: 1944
        }
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#0129BF',
                secondary: '#424242',
                accent: '#4e91fd',
                error: '#FF5252',
                info: '#141029',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
    icons: {
        iconfont: 'fa',
    },
});
