<template>
	<v-card flat class="h-100 w-100 relative">
		<v-img :src="item.image" class="rounded" style="height: 370px"/>
		<v-card-text class="pt-0 absolute b-0" style="height: 130px;">
			<div class="secondary h-100 rounded pa-4 white--text">
				<div class="mb-2" style="opacity: 0.5">{{new Date(item.date).toDateString()}}</div>
				<div class="text-h6">{{item.title.slice(0, 50)}}...</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "BlogCard",
	props: ['item']
}
</script>

