<template>
	<v-app>
		<header-component/>
		<main-component v-if="!$store.state.dialog"/>
		<footer-component v-if="!$store.state.dialog"/>
	</v-app>
</template>

<script>
import HeaderComponent from "@/components/layouts/v1/header";
import FooterComponent from "@/components/layouts/v1/footer";
import MainComponent from "@/components/layouts/v1/main";
export default {
	name: "LayoutComponent",
	components: {
		HeaderComponent,
		FooterComponent,
		MainComponent
	}
}
</script>

<style lang="scss">
nav {
	padding: 30px;
	
	a {
		font-weight: bold;
		color: #2c3e50;
		
		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>
