<template>
	<v-container class="py-16">
		<title-component class="text-h2 text-center" title="Our Services"/>
		<v-row class="my-12">
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/arrowup.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Roofs</div>
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 1</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 2</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 3</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 4</div>-->
							<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/circle.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Patios and Driveways</div>
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 1</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 2</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 3</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 4</div>-->
<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/star.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Gutters</div>
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 1</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 2</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 3</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 4</div>-->
<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/oval.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Windows</div>
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 1</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 2</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 3</div>-->
							<!--							</div>-->
							<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
							<!--								<div><i class="fas fa-check mr-4"></i></div>-->
							<!--								<div>Subservice 4</div>-->
							<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/plus.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Walls and Fences</div>
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 1</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 2</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 3</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 4</div>-->
<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="4">
				<v-card class="cardgradient rounded-xl" min-height="200">
					<img class="absolute image ma-4" :width="$vuetify.breakpoint.smAndDown ? 140 : 172" src="@/assets/images/services/triangle.png">
					<v-card-text class="relative h-100 pa-10">
						<div style="z-index:10">
							<div class="text-h5 mb-6 gray-text darken4 azonix">Soffits and Fascias</div>
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 1</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 2</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 3</div>-->
<!--							</div>-->
<!--							<div class="text-body-1 gray-text mb-2 d-flex">-->
<!--								<div><i class="fas fa-check mr-4"></i></div>-->
<!--								<div>Subservice 4</div>-->
<!--							</div>-->
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";

export default {
	name: "ServicesComponent",
	components: {
		TitleComponent
	}
}
</script>

<style>
.image{
	right: 0;
	bottom: 0;
}
</style>
