<template>
	<div class="h-100">
		<div v-if="!$store.state.dialog && $route.name == 'main'" class="d-flex justify-end w-100 absolute menu-container">
			<v-container class="d-none d-lg-block">
				<div class="d-flex justify-end py-14 white--text">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<div
								class="text-h6 px-4 text-uppercase azonix"
								v-bind="attrs"
								v-on="on"
							>Services</div>
						</template>
						<v-list dense>
							<v-list-item :to="{name: 'roofs'}">
								<v-list-item-title class="text-body-1 azonix">Roofs</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'gutter'}">
								<v-list-item-title class="text-body-1 azonix">Gutters</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'soffitfascia'}">
								<v-list-item-title class="text-body-1 azonix">Soffits and Fascias</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'window'}">
								<v-list-item-title class="text-body-1 azonix">Windows</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'conservatory'}">
								<v-list-item-title class="text-body-1 azonix">Conservatories</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'patiocriveway'}">
								<v-list-item-title class="text-body-1 azonix">Driveways and Patios</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'wallfence'}">
								<v-list-item-title class="text-body-1 azonix">Walls and Fences</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'renderwall'}">
								<v-list-item-title class="text-body-1 azonix">Render Walls</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'solarpanel'}">
								<v-list-item-title class="text-body-1 azonix">Solar Panels</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'decking'}">
								<v-list-item-title class="text-body-1 azonix">Decking</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
<!--					<router-link :to="{name: 'work'}" class="text-h6 px-4 text-uppercase azonix white&#45;&#45;text text-decoration-none">Our Work</router-link>-->
<!--					<router-link :to="{name: 'blog'}" class="text-h6 px-4 text-uppercase azonix white&#45;&#45;text text-decoration-none">Blog</router-link>-->
					<router-link :to="{name: 'contact'}" class="text-h6 px-4 text-uppercase azonix white--text text-decoration-none">Contact Us</router-link>
				</div>
			</v-container>
			<div class="d-lg-none">
				<v-icon class="ma-14 pointer" color="white" x-large @click="$store.state.dialog = true">fa-solid fa-bars</v-icon>
			</div>
		</div>
		<div v-if="!$store.state.dialog && $route.name != 'main'">
			<v-container class="justify-space-between align-center d-none d-lg-flex">
				<div class="">
					<router-link :to="{name: 'main'}" class="">
						<img src="@/assets/log.png" class="logo2">
					</router-link>
				</div>
				<div class="d-flex justify-end py-14">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<div
								class="text-body-1 font-weight-bold px-4 text-uppercase azonix grey--text text--darken-4 text-decoration-none"
								v-bind="attrs"
								v-on="on"
							>Services</div>
						</template>
						<v-list dense>
							<v-list-item :to="{name: 'roofs'}">
								<v-list-item-title class="text-body-1 azonix">Roofs</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'gutter'}">
								<v-list-item-title class="text-body-1 azonix">Gutters</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'soffitfascia'}">
								<v-list-item-title class="text-body-1 azonix">Soffits and Fascias</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'window'}">
								<v-list-item-title class="text-body-1 azonix">Windows</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'conservatory'}">
								<v-list-item-title class="text-body-1 azonix">Conservatories</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'patiocriveway'}">
								<v-list-item-title class="text-body-1 azonix">Driveways and Patios</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'wallfence'}">
								<v-list-item-title class="text-body-1 azonix">Walls and Fences</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'renderwall'}">
								<v-list-item-title class="text-body-1 azonix">Render Walls</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'solarpanel'}">
								<v-list-item-title class="text-body-1 azonix">Solar Panels</v-list-item-title>
							</v-list-item>
							<v-list-item :to="{name: 'decking'}">
								<v-list-item-title class="text-body-1 azonix">Decking</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
<!--					<router-link :to="{name: 'work'}" class="text-body-1 font-weight-bold px-4 text-uppercase azonix grey&#45;&#45;text text&#45;&#45;darken-4 text-decoration-none">Our Work</router-link>-->
<!--					<router-link :to="{name: 'blog'}" class="text-body-1 font-weight-bold px-4 text-uppercase azonix grey&#45;&#45;text text&#45;&#45;darken-4 text-decoration-none">Blog</router-link>-->
					<router-link :to="{name: 'contact'}" class="text-body-1 font-weight-bold px-4 text-uppercase azonix grey--text text--darken-4 text-decoration-none">Contact Us</router-link>
				</div>
			</v-container>
			<div class="d-lg-none">
				<div class="d-flex justify-space-between align-center pt-4 px-4">
					<div class="">
						<router-link :to="{name: 'main'}">
							<img src="@/assets/log.png" class="logo2">
						</router-link>
					</div>
					<v-icon class="ma-8 pointer" color="black" x-large @click="$store.state.dialog = true">fa-solid fa-bars</v-icon>
				</div>
			</div>
		</div>
		<div v-if="$store.state.dialog" class="h-100 w-100 primary z100">
			<div class="d-flex justify-space-between">
<!--				<img src="@/assets/log.png" class="logo-150 ma-8">-->
				
				<v-icon class="ma-14 pointer" color="white" x-large @click="$store.state.dialog = false">fa-solid fa-bars</v-icon>
			</div>
			<div>
				<v-list color="transparent text-center">
					<v-list-item :to="{name: 'main'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Home</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'roofs'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Roofs</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'gutter'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Gutters</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'soffitfascia'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Soffits and Fascias</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'window'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Windows</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'conservatory'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Conservatories</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'patiocriveway'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Driveways and Patios</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'wallfence'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Walls and Fences</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'renderwall'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Render Walls</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'solarpanel'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Solar Panels</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{name: 'decking'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Decking</v-list-item-title>
					</v-list-item>
<!--					<v-list-item :to="{name: 'work'}" @click="$store.state.dialog = false">-->
<!--						<v-list-item-title class="text-h5 white&#45;&#45;text azonix">Our Work</v-list-item-title>-->
<!--					</v-list-item>-->
<!--					<v-list-item :to="{name: 'blog'}" @click="$store.state.dialog = false">-->
<!--						<v-list-item-title class="text-h5 white&#45;&#45;text azonix">Blog</v-list-item-title>-->
<!--					</v-list-item>-->
					<v-list-item :to="{name: 'contact'}" @click="$store.state.dialog = false">
						<v-list-item-title class="text-h5 white--text azonix">Contact Us</v-list-item-title>
					</v-list-item>
				</v-list>
			</div>
			<div class="pt-8 text-right px-4">
				<div class="pb-6">
					<a href="callto:077 6546 5656" class="text-h5 white--text text-decoration-none">077 6546 5656</a>
				</div>
				<div>
					<a href="mailto:info@vmec.co.uk" class="text-h5 white--text text-decoration-none">info@vmec.co.uk</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HeaderComponent",
	data: () => ({
		sidebar: false
	})
}
</script>

<style scoped>
.logo2{
	width: 240px;
	height: auto;
}
.menu-container{
	left: 0;
	right: 0;
	top: 0;
	z-index: 100;
}
</style>
