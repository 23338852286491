<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Conservatories"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Conservatory Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						It is important that you clean your conservatory at least twice a year. This will help it stay clean and clear,  but it will also avoid the build up of dirt,  debris and grime which can lead to a damaged conservatory if not dealt with! To clean it correctly needs professional equipment,  experience and time. While we know that cleaning your conservatory is a time consuming task. Ensure it is done in the right way,  with the right tools and techniques,  to get best result for a long period of time.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Roof Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<v-btn class="primary white--text" depressed>Estimate</v-btn>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Access to the right cleaning products',
					text: 'When you choose to have your conservatory professionally cleaned,  you can be confident that the correct products will be used,  and your conservatory will be sparkling.',
				},
				{
					title: 'Avoid signs of wear and tear',
					text: 'By having your conservatory cleaned professionally,  you can ensure that it looks as good as new for many years and continues to be an asset rather than an inconvenience.',
				},
				{
					title: 'Cleaning conservatories can be tricky',
					text: 'By hiring a professional cleaner to do this task for you,  you’ll be able to save time and avoid getting frustrated,  which means that you can simply sit back and relax in your conservatory rather than worry about its maintenance.',
				},
				{
					title: 'Most importantly',
					text: 'And most importantly it allows you to enjoy it at its best.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Roof Cleaning',
				text: 'Please,  read the FAQs for the roof cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'How long will it take to clean my conservatory?',
						answer: 'This depending on the size but usually 3-4 hours for an average sized conservatory (approximately 3 x 4 metres)',
					},
					{
						quest: 'Do you stand on the conservatory?',
						answer: 'No,  we have specialist access facilities and equipment that ensures we do not stand on your conservatory.',
					},
					{
						quest: 'How do you clean my conservatory?',
						answer: 'We use low pressure equipment to deliver water safely. This ensures minimal pressure on the seals around your conservatory.',
					},
					{
						quest: 'My conservatory is dirty and green. Can you clean it?',
						answer: 'Yes, we can in most of cases.'
					},
					{
						quest: 'What chemicals do you use?',
						answer: 'We use a special developed solution that is not harmful.',
					},
					{
						quest: 'My conservatory is dirty and green. Can you clean it?',
						answer: 'Yes, we can in most cases.',
					},
					{
						quest: 'Why should my conservatory be cleaned when it goes green?',
						answer: 'The green coloured growth is usually caused by algae. This build up not only looks ugly but also obscures light from entering the conservatory. Algae begins to grow on the polycarbonate or glass and in some cases under the seals of the roof. This can force the seals apart over time and cause leaks into the conservatory.',
					},
					{
						quest: 'I have tried to clean the conservatory myself but it is still green. Can you help?',
						answer: 'In most cases,  yes. However,  we have come across conservatories where the algae has been allowed to grow over the roof for several years. This has allowed the sun to bake a coating of the algae onto the roof making it impossible to remove and causing the roof to be permanently green.',
					},
					{
						quest: 'Do you clean gutters?',
						answer: 'Yes,  we clean you conservatory gutters as part of our service. We can also clean house gutters and fascias around you house. This is at an extra cost.',
					},
				]
			},
			process: {
				items: [
					{
						text: 'Clean your gutters out as when they block and overflow this can cause damage to your conservatory and your gutters.',
					},
					{
						text: 'Take the time to clean the inside and outside of your conservatory,  all windows and doors included.',
					},
					{
						text: 'Check the locks and hinges on your conservatory windows and doors. Clean off any debris and check if they need extra oil to keep them in good working order.',
					},
					{
						text: 'If there are large debris,  twigs,  branches on your conservatory roof we clear this off to reduce the risk of damage to your conservatory.',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
