<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Decking"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Decking Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						Untreated, dirty decking will rot and need to be changed or become a slip hazard when wet or covered in algae. To restore your decking’s beauty and keep it’s value, you need an expert clean that won’t  damage the wood, so you can entertain family and friends in your garden throughout the year <br/>
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Decking Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" md="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor-white.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" md="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Remove debris and leaves',
					text: 'One of the main benefits of roof cleaning is that it removes debris and leaves that can clog your gutters and down spouts.',
				},
				{
					title: 'Prevent moss and algae growth',
					text: 'Moss and algae can grow on your roof,  and while they may not seem like a big deal,  they can actually cause serious damage.',
				},
				{
					title: 'Improve curb appeal',
					text: 'A clean roof can do wonders for your home’s curb appeal. If you just want it to look its best,  roof cleaning can make a big difference.',
				},
				{
					title: 'Extend the life of your roof',
					text: 'One of the best benefits of roof cleaning is that it can actually extend the life of your roof.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Roof Cleaning',
				text: 'Please,  read the FAQs for the roof cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'Why is roof cleaning important?',
						answer: 'It’s easy to forget about the roof of your home,  but it is a very important part of the building. Generally,  the roof doesn’t need too much attention,  but every three to five years,  roofs can benefit from a clean. Roof cleaning removes lichen,  moss,  and algae from the surface. A build-up can stain your roof and begin to damage the surface material. ' +
							'<br/><br/>A periodical roof cleaning will slow the growth of mildew,  and make any cracks or splits easier to spot from the ground. You will also see a reduction in the amount of moss falling into the gutters.',
					},
					{
						quest: 'Can I complete roof cleaning myself?',
						answer: 'Cleaning a roof yourself isn’t an easy job. To make sure you are safe,  you’ll need scaffolding and a washing system that won’t damage roofing material. Without the right equipment,  you could damage your roof,  make a mess on your walls and put yourself in danger. Save yourself the stress and hard work and book a roof cleaning specialist.' +
							'<br/><br/>We prepare the ground with sheeting to protect the area and use the best equipment available that is specially designed to clean roofs perfectly. The guttering is cleared as part of the process and we tidy up after ourselves.',
					},
					{
						quest: 'Will the soft wash chem harm my roof?',
						answer: 'We use a safe non aggressive product that has an excellent environmental footprint. The product does not damage the material being treated and there is no physical alteration of the surface.',
					},
					{
						quest: 'Will the roof be cleaned with instant results?',
						answer: 'We remove 95% of moss by hand and  soft wash the roof with professional chemical. The cleansing progresses starts immediately and the original colour of the roof tiles starts to come back within 2 hours. The moss and lichen is killed instantly and typically 12 months to erode away from the roof structure,  this is dependent on weather conditions (rain/wind etc).',
					},
					{
						quest: 'Is your product safe to homeowners and animals?',
						answer: 'It is safe to you and your animals.',
					},
					{
						quest: 'Will you walk on my roof?',
						answer: 'We do not walk on roofs and to gain access we use roof ladders and scaffolding.',
					},
					{
						quest: 'Do you remove any moss?',
						answer: 'We remove by hand 95% of moss.',
					},
					{
						quest: 'Can you undertake any roof repairs?',
						answer: 'We are not a roofing company and we recommend professional roof installers to carry out any repairs if any. ',
					},
					{
						quest: 'Can moss on my roof be damaging?',
						answer: 'Moss generally grows in the vertical joint between tiles and often at the tail end. The end result is increased water ingress where tiles overlap which can damage the fixings and timber battens.',
					},
					{
						quest: 'Are you insured?',
						answer: 'We are fully insured for your complete peace of mind.',
					},
					{
						quest: 'Will you leave our property clean and tidy?',
						answer: 'Cleaning patios and driveways is a messy job however we will leave your property clean and tidy.',
					},
					{
						quest: 'How long will the roof cleaning last?',
						answer: 'We cannot give you an exact timescale however typically we find that 5 to 8 years is possible. This all depends on mother nature and how aggressive the winters are during this period. Also cross contamination from trees or neighbouring properties can affect this timescale.',
					},
				]
			},
			process: {
				items: [
					{
						text: 'We scrape the worst <br/> of the debris off the roof',
					},
					{
						text: 'Apply a layer of biochemicals to break down and loosen remaining mildew,  moss and algae',
					},
					{
						text: 'Gently wash the solution <br/>(and all the grime) off the roof',
					},
					{
						text: 'We can then apply a sealant to protect against weather and keep the roof in good shape for longer',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
