<template>
    <div class="extensionblock">
        <v-expansion-panels flat>
            <v-expansion-panel
                class="transparent"
                v-for="(item, i) in items"
                :key="i"
            >
                <v-expansion-panel-header class="text-20-60 py-7 px-0">
                    {{item.quest}}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="w-80 mb-5" v-html="item.answer"></v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
export default {
    name: 'ExtensionBlock',
    props: ['items']
}
</script>

<style lang="scss">
.extensionblock{
    .v-expansion-panel{
        border-bottom: 1px solid #CDE0FF;
    }

    .v-expansion-panel:first-child {
        border-top: 1px solid #CDE0FF;

    }
}
</style>
