import { render, staticRenderFns } from "./WallFencePage.vue?vue&type=template&id=f099b692&scoped=true&"
import script from "./WallFencePage.vue?vue&type=script&lang=js&"
export * from "./WallFencePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f099b692",
  null
  
)

export default component.exports