<template>
	<div class="blue py-3">
    <div class="callbutton"><a class="text-decoration-none" href="tel:07765465656"><img class="w-100 pointer" src="@/assets/img/call.webp"/></a></div>
		<v-container class="white--text">
			©2022  V M Exterior Cleaning
		</v-container>
	</div>
</template>

<script>
export default {
	name: "FooterComponent"
}
</script>

<style scoped>
.callbutton{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 80px;
  margin-bottom: 20px;
  margin-right: 30px;
}

</style>
