<template>
	<v-container class="py-16">
		<title-component class="text-h2" title="About the Company"/>
		
		<v-card class="my-12 cardgradient rounded-xl">
			<v-card-text class="py-10">
				<v-row>
					<v-col cols="12" md="12" class="text-left">
						<p class="text-center">
							Our company was established with cleanliness at the heart and with the clear intention of helping you maximise the value of your property by inspecting, cleaning and maintaining the essential areas of your home and, therefore,  avoiding costly repair bills.
						</p>
					</v-col>
<!--					<v-col cols="12" md="6">-->
<!--						<img src="@/assets/img/main_about.jpg" class="mw-100 rounded-xl" style="height: 350px"/>-->
<!--					</v-col>-->
				</v-row>
			</v-card-text>
		</v-card>
		<v-row class="my-12">
			<v-col cols="12" xl="5">
				<v-card class="text-center py-10 blue rounded-xl">
					<v-card-title class="justify-center text-h4 white--text">
						<div class="iconaboutcards secondary rounded-circle d-flex align-center justify-center">
							<v-icon color="white" large>far fa-flag</v-icon>
						</div>
					</v-card-title>
					<v-card-title class="justify-center text-h4 white--text azonix">Our Mission</v-card-title>
					<v-card-text class="white--text">We all know the British weather can reek havoc on areas that are hard to reach because they are just that - hard to reach - and you don’t necessarily have the specialist equipment needed to do an expert job. That’s where we come in. Your roof, soffits, fascias, gutters, walls etc, protect the whole of your property keeping it well insulated, dry, leak and damage free.</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" xl="3">
				<v-card class="text-center py-10 blue rounded-xl">
					<v-card-title class="justify-center text-h4 white--text">
						<div class="iconaboutcards secondary rounded-circle d-flex align-center justify-center">
							<v-icon color="white" large>far fa-eye</v-icon>
						</div>
					</v-card-title>
					<v-card-title class="justify-center text-h4 white--text azonix">Our Vision</v-card-title>
					<v-card-text class="white--text">Our vision is to enable householders and local communities to maintain their properties, buildings, even statues and memorials, clean and damage free, avoiding costly repairs by giving them the care and attention they deserve.<div class="d-none d-lg-block d-xl-none blue--text">.</div></v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" xl="4">
				<v-card class="text-center py-10 blue rounded-xl">
					<v-card-title class="justify-center text-h4 white--text">
						<div class="iconaboutcards secondary rounded-circle d-flex align-center justify-center">
							<v-icon color="white" large>far fa-heart</v-icon>
						</div>
					</v-card-title>
					<v-card-title class="justify-center text-h4 white--text azonix">Our Values</v-card-title>
					<v-card-text class="white--text">We want to provide quality, honest and reliable services that you will be happy to recommend to family, friends and neighbours. We want our services to allow you to keep the highest possible value on your property, avoid costly repairs and provide the TLC needed to keep your home in tip-top condition. </v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
export default {
	name: "AboutComponent",
	components: {
		TitleComponent
	}
}
</script>

<style scoped>
.iconaboutcards{
	width: 100px;
	height: 100px;
}
</style>
