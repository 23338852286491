<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Soffits and Fascias"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Soffit and Fascia Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						Fascias and soffits play a significant role in the protection and security of our homes,  acting as a barrier to prevent moisture from penetrating your roof,  leading to rot or decay. Keeping your property safe from structural damage and improving the overall appearance of your property.
						<br/>Without a working soffit and fascia,  your shingles may curl and even come off the roof. Moisture,  mold,  and mildew can get into your home,  wood can rot,  and you can face tremendous problems. On a domestic property,  it is absolutely necessary to maintain the UPVC to protect your investment. The cost to replace Fascia and Soffit Boards is extremely high. Why not have them cleaned once every year or 2 years to maintain these areas and avoid a costly replacement.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Soffit and Fascia Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item,  key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";

export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'All waste is collected',
					text: 'No Mess-No Fuss,  all waste is collected in the vacuum and environmentally disposed of offsite at no extra cost.',
				},
				{
					title: 'Includes downpipes and hoppers',
					text: 'We clean as part of the service not only the gutters,  but the full system including downpipes and hoppers at no extra cost.',
				},
				{
					title: 'Make your property stand out',
					text: 'With this service will lift and improve the appearance of your property making it look new and fresh.',
				},
				{
					title: 'Reliable family run business',
					text: 'We use carefully chosen staff who are fully trained,  uniformed and committed to our high standards.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Roof Cleaning',
				text: 'Please,  read the FAQs for the roof cleaning. If you have any additional questions,  don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'What are Soffits?',
						answer: 'The Soffit is the main element people see from street level. The Soffit is attached to the bottom of the Fascia board and is usually vented to allow air to flow through the roof area. It is important to note that poor ventilation or blockages can cause timber decay as condensation forms in the roof void.',
					},
					{
						quest: 'What are Fascias?',
						answer: 'The Fascia is the long board that holds the guttering and runs horizontally along the underneath edge of the roof.',
					},
					{
						quest: 'How do you clean Fascia and Soffits?',
						answer: 'Cleaning your Fascias and Soffits is done by the reach and wash system using a detergent and a different brush to remove any green algae and mould that has built up over time ,  this service also includes cleaning the outside of the gutters and a free window clean.',
					},
					
					
				]
			},
			process: {
				items: [
					{
						text: 'Before you get started soaping up your soffits and fascias,  first soak them with clean water,  rinsing away all debris and dirt.',
					},
					{
						text: 'For a thorough clean,  you will need to use detergent after your initial rinse. When applying the detergent,  fill your tank with detergent. ',
					},
					{
						text: 'Next,  you will want to attach a squeeze trigger nozzle on to the end of your garden hose and turn the pressure on high. ',
					},
					{
						text: 'Move your ladder to the next position and repeat the scrubbing and rinsing process until all of your soffit and fascia has been cleaned. ',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
