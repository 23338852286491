<template>
    <div class="counterinterface heading-extra white--text text-h3">
        <number
            class="transition"
            ref="number"
            :from="0"
            :format="theFormat"
            animationPaused
            :to="parseInt(item.quantity)"
            :duration="5"
            easing="Power4.easeOut"
            v-observe-visibility="{ callback: playAnimation, once: true }"/>
        {{item.unit}}
    </div>
</template>

<script>
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility';
import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)

export default {
    name: 'CounterInterface',
    props: ['item'],
    directives: {
        VueNumber,
        ObserveVisibility
    },
    methods: {
        theFormat(number) {
            return number.toFixed(0);
        },
        playAnimation(isVisible) {
            if(isVisible)
                this.$refs.number.play();
        }
    }
}
</script>

<style lang="scss">
.counterinterface{
    .transition {
      transition: all 0.3s ease-in;
    }
}
</style>
