<template>
	<div>
		<v-container class="pb-16">
			<div class="pb-6">
				<v-btn class="transparent" depressed>
					<v-icon class="mr-2">fa-regular fa-circle-left</v-icon>
					Go back
				</v-btn>
			</div>
			<title-component class="text-h1 azonix text-center py-16" title="Gutters"/>
			
			<v-row class="my-md-10 howwork">
				<v-col cols="12" lg="6" class="pt-10">
					<title-component class="text-h2" title="Professional Gutter Cleaning Service"/>
					<p class="text-body-1 my-10 d-none d-sm-block">
						Gutters play an integral role in protecting your home from water damage. A blocked gutter might seem like a trivial problem at first. But if left unchecked, can cause serious damage to your property.<br/>Blocked gutter damage can quickly turn into a huge and costly problem, leading to structural damage, leaking roofs, insect infestations, damage to the foundations, leaking basements, broken gutters, damage to landscaping and fascia, black mould on walls. Speaking in general terms, you should make a point of cleaning your gutters at least twice a year.
					</p>
					<v-btn large class="primary px-15 py-8 mt-5 text-transform-none text-14 d-none d-sm-inline-flex rounded-xl" depressed>Get Request</v-btn>
				</v-col>
				<v-col cols="12" lg="5" offset-lg="1" class="pl-auto pl-sm-0 pl-md-auto py-0">
					<how-work :items="service.list" ></how-work>
					<v-btn x-large class="secondary px-10 py-4 mt-5 text-transform-none text-14 d-sm-none" depressed :to="{name: 'frontend.calculator'}">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
				</v-col>
			</v-row>
		</v-container>
		
		<div class="py-16 grey lighten-4">
			<v-container class="py-10">
				<title-component class="text-h2 mb-16" title="Roof Cleaning <br/>Benefits"/>
				<v-row>
					<v-col cols="12" sm="6" lg="3" v-for="(item,  key) in service.benefits" :key="key">
						<star-card :color="item.bg" :white="item.white" class="rounded-xl">
							<template v-slot:title>
								{{item.title}}
							</template>
							<template v-slot:description>
								{{item.text}}
							</template>
						</star-card>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<page-counter :bgimg="require('@/assets/logo-bicolor.png')" :items="service.counter">
			<h1 class="text-h2 white--text mb-10">We have  <br/>a solid experience  <br/>of exterior cleaning</h1>
		</page-counter>
		
		<div class="py-16 grey lighten-4">
			<faq-block class="py-16" :data="service.faq"/>
		</div>
		<div class="blue py-16">
			<v-container class="d-md-flex justify-center">
				<div class="text-h4 white--text mr-4">
					Send a request for estimate price
				</div>
				<div class="text-center">
					<v-btn class="mt-6 mt-md-0 primary white--text text-center" depressed>Estimate</v-btn>
				</div>
			</v-container>
		</div>
		<v-container class="py-16">
			<title-component class="text-h2 py-16" title="What goes into?"/>
			<v-row>
				<v-col cols="24" sm="6" lg="3" v-for="(item, key) in service.process.items" :key="key">
					<number-card class="rounded-xl">
						<template v-slot:number>
							{{ key + 1 }}
						</template>
						<template v-slot:description>
							<span class="text-body-1" v-html="item.text"/>
						</template>
					</number-card>
				</v-col>
			</v-row>
		</v-container>
		<contact-component/>
	</div>
</template>

<script>
import TitleComponent from "@/components/base/TitleComponent";
import ContactComponent from "@/components/pages/sections/ContactComponent";
import StarCard from "@/components/base/StarCard.vue";
import PageCounter from "@/components/pages/PageCounter.vue";
import faqBlock from "@/components/pages/FAQ.vue";
import NumberCard from "@/components/base/NumberCard.vue";
import HowWork from "@/components/pages/HowWork.vue";
export default {
	name: "RoofPage",
	components: {
		TitleComponent,
		ContactComponent,
		StarCard,
		PageCounter,
		faqBlock,
		NumberCard,
		HowWork
	},
	data: () => ({
		service: {
			list: [
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Only Eco-friendly products',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Outstanding results',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Solution to Suit every budget',
				},
				{
					icon: require('@/assets/svg/dir.svg'),
					text: 'Long-term Warranties',
				},
				{
					icon: require('@/assets/svg/msg.svg'),
					text: 'Guaranteed Satisfaction',
				},
			],
			benefits: [
				{
					title: 'Reduces Pests',
					text: 'Clogged gutters tend to attract nesting birds, rodents, and a lot of bugs, all of which can carry germs and disease. By removing leaves and debris, it discourages birds and pests making your gutters home.',
				},
				{
					title: 'Stops Overflow',
					text: 'When gutters are clogged, they quickly overflow during heavy rainfall. This can cause unnecessary damage to your landscaping, garden, and can cause damp on exterior walls if it goes unfixed.',
				},
				{
					title: 'Boost Kerb Appeal',
					text: 'Dirty, clogged gutters look awful and are a sure-fire way to make your house look unloved. Whether you’re planning to sell your house or not, having sparkling clean gutters boosts your kerb appeal and makes your house look its best.',
				},
				{
					title: 'Stop Water Damage',
					text: 'When gutters overflow and exterior walls become damp, it can quickly lead to water damage inside the house. The water can quickly soak into the foundations or through exterior walls and create unsightly damp patches.',
				},
			],
			counter: [
				{
					quantity: 5,
					unit: 'k',
					text: 'Over 5 k of loyal <br/> clients trust us',
				},
				{
					quantity: 5,
					unit: 'y',
					text: 'We have been providing services <br/>for more than 5 years',
				},
				{
					quantity: 80,
					unit: 'k',
					text: 'We have more than <br/>80, 000 pounds worth of equipment',
				},
			],
			faq: {
				title: 'Frequently Asked Questions about Gutter Cleaning',
				text: 'Please, read the FAQs for the gutter cleaning. If you have any additional questions, don’t hesitate to contact us info@vmec.co.uk',
				items: [
					{
						quest: 'Why spotless gutters are so important?',
						answer: 'Blocked gutters can cause a lot of damage to your property.<br/>When rainwater can’t flow properly along with the guttering and through the downpipe, it starts spilling over the edge of the gutters and down the walls.<br/>You can end up with damp walls and algae on the exterior surface.<br/>A continuous dripping noise often sets in whenever it rains, this can wake you at night and be very frustrating for you and your neighbours.<br/>' +
							'Clogged gutters look dirty and can affect the look of your home.<br/>Fortunately, all of this can be avoided with regular cleaning and clearing of your gutters. Once we are finished, not only will the rainwater flow efficiently through the gutters, but the outside of your home will look fantastic too.',
					},
					{
						quest: 'How do you clean out our gutters?',
						answer: 'We use our powerful gutter vac, with strong poles and special developed heads and nozzles, to pick all deposits from your gutters.',
					},
					{
						quest: 'Can you remove large weeds from my gutters?',
						answer: 'We use a special head to cut the soil and weeds, then safely remove them. We don\'t recommend waiting until the gutters are full and the weeds grow. It is not safe and may need expensive repairs.',
					},
					{
						quest: 'Can I be sure my gutters have been properly cleared by you?',
						answer: 'You can ask for "before" and "after" photos of your gutters.',
					},
					{
						quest: 'How much does this gutter cleaning service cost?',
						answer: 'When the customer asks for a quote, he or she gets the price.',
					},
					{
						quest: 'How do I get a quote please?',
						answer: 'If you need a quote, contact us.',
					},
					{
						quest: 'How long do I have to wait for a gutter clearing?',
						answer: 'After the customer  contacted us for a quote, we will discuss about the soonest and most convenient date.'
					},
					{
						quest: 'How long do I have to wait for a gutter clearing?',
						answer: 'After the customer  contacts us for a quote. We will discuss the soonest and most convenient date.',
					},
					{
						quest: 'Do I need to be there for you to clear my gutter?',
						answer: 'To carry out a gutter clean we will need access to the front and back areas. You don’t necessarily need to be there for us to carry out the clean as we carry our own generator for the electricity. On completion of the clean we will leave you an invoice where you can pay by Internet banking cheque-book or cash.',
					},
					{
						quest: 'I have a shared down pipe with my neighbour?',
						answer: 'We will just clear just your gutter run if you want, but you can speak to your neighbour to take advantage of our neighbour discount if both jobs are booked at the same time and both gutter clears are completed on the same day.',
					},
					{
						quest: 'Do you wash gutters and fascias ?',
						answer: 'We can do this for you and it will be double the price of the gutter clean quote. Clean the out side of the Gutters/Fascias/Soffits and Down Pipe. We also apply a specially formulated soft wash chemical that will clean and also kill all the algae, lichen growths',
					},
					{
						quest: 'Do you have insurance?',
						answer: 'We are fully insured for your complete peace of mind.',
					},
					{
						quest: 'Will you leave our property clean and tidy?',
						answer: 'We will leave your property clean and tidy.',
					}
				]
			},
			process: {
				items: [
					{
						text: 'Remove all moss, leaves and debris',
					},
					{
						text: 'Apply smart cleaning solution products',
					},
					{
						text: 'Wash all exterior areas of gutters and fascias',
					},
					{
						text: 'We take all the mess and debris away with us',
					}
				]
			}
		}
	})
}
</script>

<style scoped>

</style>
